<script>
import draggable from "vuedraggable";

export default {
    name: "simple-table",

    emits: ['sorted'],

    components: {draggable},

    props: {
        fields: {
            type: Array,
            default: () => { return []; }
        },

        items: {
            type: Array,
            default: () => { return []; }
        },

        form: {
            type: String
        },

        sortable: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        hasFooter: function () {
            return this.$slots && Object.keys(this.$slots).findIndex(slot => slot.startsWith('foot_')) !== -1;
        },

        hasModal: function () {
            return this.$slots && this.$slots.default;
        },
    },

    data: function () {
        return {
            edited: null,
            draggableItems: [],
            showModal: false,
            showResponsiveActions: false
        }
    },

    methods: {
        select: function () {

        },

        edit: function (item) {
            this.edited = item;
            this.showModal = true;
        },

        close: function () {
            this.edited = null
        },

        changeSort: function () {
            this.$emit('sorted', this.draggableItems);
        },

        showFixedActions: function () {
            if (this.$refs.actions?.[0]) {
                this.showResponsiveActions = (this.$refs.responsive.offsetWidth + this.$refs.responsive.scrollLeft) <= (this.$refs.responsive.scrollWidth - this.$refs.actions?.[0]?.offsetWidth);
            }
        },
    },

    watch: {
        items: {
            immediate: true,
            deep: true,
            handler: function (value) {
                this.showFixedActions();
                this.draggableItems = JSON.parse(JSON.stringify(value || []));
            },
        }
    },

    created() {
        window.addEventListener("resize", this.showFixedActions);
    },

    mounted() {
        this.showFixedActions();
    },

    activated() {
        this.showFixedActions();
    },

    unmounted() {
        window.removeEventListener("resize", this.showFixedActions);
    },
}
</script>

<template>
    <div class="table-responsive" ref="responsive" @scroll="showFixedActions">
        <table class="table project-list-table table-nowrap align-middle table-borderless" :class="{responsive: showResponsiveActions}">
            <thead>
                <tr>
                    <th v-if="sortable"></th>
                    <th v-for="(field, index) in fields" :key="index" :class="field.thClass" :ref="field.key">
                        <slot :name="field.key + '_header'" :value="field.label">
                            {{ field.label }}
                        </slot>
                    </th>
                </tr>
            </thead>
            <tbody v-if="!sortable">
                <template v-for="(item, index) in items" :key="index">
                    <tr>
                        <td v-for="(field, fieldIndex) in fields" :key="fieldIndex" :class="typeof field.tdClass === 'function' ? field.tdClass(item[field.key], fieldIndex, item, (field.formatter ? field.formatter(item[field.key], fieldIndex, item) : item[field.key])) : field.tdClass">
                            <slot :edit="edit" :close="close" :name="field.key" :index="index" :item="item" :field="field" :value="item[field.key]" :formatted="(field.formatter ? field.formatter(item[field.key], fieldIndex, item) : null)">
                                {{ field.formatter ? field.formatter(item[field.key], fieldIndex, item) : item[field.key] }}
                            </slot>
                        </td>
                    </tr>
                    <tr v-if="!!$slots.default && edited?.id === item?.id">
                        <td :colspan="fields.length">
                            <slot :close="close" :item="edited"></slot>
                        </td>
                    </tr>
                </template>
            </tbody>
            <draggable
                v-else
                tag="tbody"
                v-model="draggableItems"
                item-key="id"
                @change="changeSort"
            >
                <template #item="{element, index}">
                    <tr :key="index">
                        <td>
                            <span class="cursor-move px-2">
                                <i class="fas fa-arrows-alt-v"></i>
                            </span>
                        </td>
                        <td v-for="(field, fieldIndex) in fields" :key="fieldIndex" :class="typeof field.tdClass === 'function' ? field.tdClass(element[field.key], fieldIndex, element, (field.formatter ? field.formatter(element[field.key], fieldIndex, element) : element[field.key])) : field.tdClass">
                            <slot :edit="edit" :close="close" :name="field.key" :item="element" :field="field" :value="element[field.key]" :formatted="(field.formatter ? field.formatter(element[field.key], fieldIndex, element) : null)">
                                {{ field.formatter ? field.formatter(element[field.key], fieldIndex, element) : element[field.key] }}
                            </slot>
                        </td>
                    </tr>
                </template>
            </draggable>
            <tfoot v-if="hasFooter">
                <tr>
                    <td v-for="(field, index) in fields" :key="index" :class="field.thClass" class="font-weight-bold bg-soft-primary">
                        <slot :name="'foot_' + field.key"></slot>
                    </td>
                </tr>
            </tfoot>
        </table>
        <b-modal v-if="hasModal" v-show="showModal">
            <template #default>
                <slot :close="close" :item="edited || {}"></slot>
            </template>
        </b-modal>
    </div>
</template>

<style scoped lang="scss">
    @use "@/assets/scss/bootstrap.scss" as colors;

    table.responsive {
        thead tr th:last-of-type, tbody tr td:last-of-type, tfoot tr td:last-of-type {
            position: sticky;
            right: 0;
            @extend .bg-light;
        }
    }
</style>
